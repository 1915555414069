<template>
    <div class="maincontent">
        <div class="container">
            <div class="row">
                <div class="col-12 headerPic" style="height:500px">
                    <vueper-slides ref="myVueperSlides" fixedHeight=600px :parallax="-1" :parallax-fixed-content="false">
                        <vueper-slide
                            v-for="(slide, i) in slides" :key="i"
                            :image="require(`@/assets/${slide.image}`)" 
                            :style="'min-height: 100%'"/>
                        </vueper-slides>
                    <!-- <div class="withBackgroundImage d-flex justify-content-center"></div> -->
                </div>
            </div>
            <div class="row d-flex justify-content-center description">
                <div class="col-12 offset-2">
                    <h3>Allgemeine Objektbeschreibung</h3>
                </div>
                <div class="col-12 col-md-5 order-1 order-md-0 pt-4">
                    <p class="text-muted">
                        Der moderne Neubau befindet sich in der Julius-Vossler-Straße 90 a-c in 22527 Hamburg Lokstedt.
                    </p>
                    <p class="text-muted">
                        Hamburgs westlicher Bereich mit den vielseitigen Stadtteilen, weitläufigen Grünanlagen 
                        sowie umfangreiche Freizeit- und Sportangebote und die ausgezeichnete Infrastruktur tragen 
                        zur Wohnqualität bei.
                    </p>
                    <p class="text-muted">
                        Die Wohnungen liegen in unmittelbarer Nähe zur U-Bahn-Station Hagenbeck, ca. 5 Gehminuten, zahlreiche
                        Busverbindungen an der U-Bahn-Station sowie ein Supermarkt und der Haupteingang
                        Hagenbecks Tierpark. Auch die Autobahn A7 mit der Auffahrt Stellingen ist
                        nur wenige Minuten mit dem Auto entfernt. Die Wolfgang-Meyer Sportplätze, u.a. mit Fußball-,
                        Tennis- und Squash-Plätzen sind auch im nahen Einzugsbereich sowie das
                        Niendorfer Gehege als beliebtes Naherholungsgebiet. Auch die sehr beliebte
                        Osterstraße im Stadtteil Eimsbüttel ist schnell mit dem Fahrrad erreicht und lädt mit den vielen Geschäften und Restaurants
                        zum Bummeln und Verweilen ein.
                    </p>
                    <p class="text-muted">
                        Darüber hinaus sind alle notwendigen schulischen Einrichtungen in der näheren Umgebung zu finden.
                        Für die Kleinsten unter uns befinden sich mehrere Kitas in fußläufiger Umgebung. Um die elemetare Schulausbildung
                        abzuschließen, sind mehrere Grundschulen erreichbar, die Grundschule in der Vizelinstraße, die Grundschule Hinter der Lieth und 
                        die Grundschule Brehmweg.
                        Für die weiterführende Schulausbildung findet sich eine Haupt- und Realschule in der Emil-Andresen-Straße und ein Gymnasium in der Corveystraße.
                    </p>
                    <a href="https://goo.gl/maps/6u7oJhsrsn7mxVYs8" target="blank" class="text-muted">Ansicht auf google maps</a>
                </div>
                <div class="col-12 col-md-5 order-0 order-md-1 pt-4">
                    <img style="max-width:100%" src="@/assets/Stadtplan.jpg" alt="Stadtplan">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'
export default {
  name: 'Location',
  components: {
    VueperSlides, VueperSlide
  },
    data (){
        return {
            search: true,
            filter: '',
            selectOption: '',
            selectLength: '',
            slides:[
                {
                    image:"JVS90_Perspektiven.jpg",
                    title: 'Slide 1',
                    content: 'Slide 1 content.'
                }
            ]
        }
    },
    methods:{
        
    },
    computed:{
        
    },
    created(){
    }

}
</script>

<style scoped>
@media(max-width:576px){
    .headerPic{
        padding: 0 !important
    }
}
.headerPic{
    padding-left: 15px;
    padding-right: 15px;
}
.description{
    margin-top: 150px;
    font-size: 1.2rem;
}
</style>